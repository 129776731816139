import React, { Fragment } from "react";
import Footer from "./Footer"
import ReactModal from 'react-modal';

class CaseStudies extends React.Component {
    constructor() {
        super();

        this.state = {
            showLaundromat: false,
            showCity: false,
            showGallery: false,

        };

        this.handleOpenLaundromat = this.handleOpenLaundromat.bind(this);
        this.handleCloseLaundromat = this.handleCloseLaundromat.bind(this);
        this.handleOpenCity = this.handleOpenCity.bind(this);
        this.handleCloseCity = this.handleCloseCity.bind(this);
        this.handleOpenGallery = this.handleOpenGallery.bind(this);
        this.handleCloseGallery = this.handleCloseGallery.bind(this);
    }

    handleOpenLaundromat() {
        this.setState({ showLaundromat: true });
    }

    handleCloseLaundromat() {
        this.setState({ showLaundromat: false });
    }

    handleOpenCity() {
        this.setState({ showCity: true });
    }

    handleCloseCity() {
        this.setState({ showCity: false });
    }

    handleOpenGallery() {
        this.setState({ showGallery: true });
    }

    handleCloseGallery() {
        this.setState({ showGallery: false });
    }

    UNSAFE_componentWillMount() {
        ReactModal.setAppElement('body');
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <Fragment>
                <h2 className="text-center" id="caseTitle" >Gallery</h2>
                <div className="maxWidth">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="content">
                                <div className="content-overlay"></div>
                                <img className="content-image" src="assets/gallery1.jpg" alt="" />
                                <div className="content-details fadeIn-bottom">
                                    <h3 className="content-title">Govett-Brewster Art Gallery / Len Lye centre</h3>
                                    <p className="content-text"><i className="fa fa-map-marker"></i>New Plymouth</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="content">
                                <div className="content-overlay"></div> <img className="content-image" src="assets/gallery2.jpg" alt="" />
                                <div className="content-details fadeIn-bottom">
                                    <h3 className="content-title">All Blacks Experience</h3>
                                    <p className="content-text"><i className="fa fa-map-marker"></i>Auckland</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="content">
                                <div className="content-overlay"></div> <img className="content-image" src="assets/gallery3.png" alt="" />
                                <div className="content-details fadeIn-bottom">
                                    <h3 className="content-title">City Gallery</h3>
                                    <p className="content-text"><i className="fa fa-map-marker"></i>Wellington</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="content">
                                <div className="content-overlay"></div> <img className="content-image" src="assets/gallery4.png" alt="" />
                                <div className="content-details fadeIn-bottom">
                                    <h3 className="content-title">Puke Ariki Museum</h3>
                                    <p className="content-text"><i className="fa fa-map-marker"></i>New Plymouth</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="content">
                                <div className="content-overlay"></div> <img className="content-image" src="assets/gallery5.png" alt="" />
                                <div className="content-details fadeIn-bottom">
                                    <h3 className="content-title">City Gallery</h3>
                                    <p className="content-text"><i className="fa fa-map-marker"></i>Wellington</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="content">
                                <div className="content-overlay"></div> <img className="content-image" src="assets/gallery6.png" alt="" />
                                <div className="content-details fadeIn-bottom">
                                    <h3 className="content-title">Puke Ariki Library</h3>
                                    <p className="content-text"><i className="fa fa-map-marker"></i> New Plymouth</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="content">
                                <div className="content-overlay"></div> <img className="content-image" src="assets/gallery7.png" alt="" />
                                <div className="content-details fadeIn-bottom">
                                    <h3 className="content-title">Mall Dry Cleaning PWC</h3>
                                    <p className="content-text"><i className="fa fa-map-marker"></i> Wellington</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="content">
                                <div className="content-overlay"></div> <img className="content-image" src="assets/gallery8.png" alt="" />
                                <div className="content-details fadeIn-bottom">
                                    <h3 className="content-title">Mall Dry Cleaning Tory St</h3>
                                    <p className="content-text"><i className="fa fa-map-marker"></i> Wellington</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="content">
                                <div className="content-overlay"></div> <img className="content-image" src="assets/gallery9.png" alt="" />
                                <div className="content-details fadeIn-bottom">
                                    <h3 className="content-title">Space Place</h3>
                                    <p className="content-text"><i className="fa fa-map-marker"></i>Wellington</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </Fragment>
        );
    };
}

export default CaseStudies;

