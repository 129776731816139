import React, { Fragment } from "react";
import ReactModal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

class Nav extends React.Component {
    constructor() {
        super();
        this.handleOpenVideo = this.handleOpenVideo.bind(this);
    }

    handleOpenVideo() {
        let link = document.createElement('a');
        link.href = "assets/Sign Sync Demo_720.mp4";
        link.target = "_blank";
        link.click ();
    }

    UNSAFE_componentWillMount() {
        ReactModal.setAppElement('body');
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <Fragment>
                <div className="maxWidth">
                    <nav id="menubar" className="navbar navbar-light">

                        <a className="navbar-brand" href="#/">
                            <img src="assets/TOU SignSync logo 1x.png" id="SSlogo" alt="SignSync Logo" />
                        </a>

                        <ul className="nav justify-content-right">
                            <li className="nav-item font-weight-bold">
                                <a className="nav-link" href="/">ABOUT</a>
                            </li>

                            <li className="nav-item font-weight-bold">
                                <a className="nav-link" href="#/cases">GALLERY</a>
                            </li>

                            <li className="nav-item font-weight-bold">
                                <a className="nav-link" href="#/pricing">PRICING</a>
                            </li>

                            <li className="nav-item font-weight-bold">
                                <a className="nav-link LP" onClick={this.handleOpenVideo}>PREVIEW</a>
                            </li>
                            <li className="nav-item font-weight-bold">
                                <a className="nav-link" href="#/contact">CONTACT</a>
                            </li>

                            <li className="nav-item font-weight-bold">
                                <a className="nav-link" id="button" href="https://app.signsync.co.nz">LOGIN</a>
                            </li>
                        </ul>

                        <button className="navbar-toggler" id="hamburger" type="button" data-toggle="collapse" data-target="#navbarSupportedContent15"
                            aria-controls="navbarSupportedContent15" aria-expanded="false" aria-label="Toggle navigation">
                            <img className="navbar-toggler-icon" src="assets/menu-2.png" />
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent15">

                            <ul className="navbar-nav mr-auto justify-content-right">
                                <li className="nav-item font-weight-bold">
                                    <a className="nav-link" href="#/">About</a>
                                </li>

                                <li className="nav-item font-weight-bold">
                                    <a className="nav-link" href="#/cases">Gallery</a>
                                </li>

                                <li className="nav-item font-weight-bold">
                                    <a className="nav-link" onClick={this.handleOpenVideo}>Preview</a>
                                </li>

                                <li className="nav-item font-weight-bold">
                                    <a className="nav-link" href="#/contact">Contact</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </Fragment>
        );
    };
}

export default Nav;
