
import React from 'react';
import { HashRouter as Router, Route } from "react-router-dom";
import Nav from './Nav';
import Content from './Content';
import Contact from './Contact';
import CaseStudies from './CaseStudies';
import ScaleCalculator from './ScaleCalculator';

import './Print.css'

function App() {
    return (
        <Router>
            <Nav />
            <Route exact path="/" component={Content} />
            <Route exact path="/pricing" component={ScaleCalculator} />
            <Route exact path="/cases" component={CaseStudies} />
            <Route exact path="/contact" component={Contact} />
        </Router>
    )
}

export default App