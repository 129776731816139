import React, { Fragment } from "react";

class Footer extends React.Component {
	render() {

		return (
			<Fragment>
				<div className="maxWidth">
					<div className="footer">
						<div className="justify-content-center">
							<a href="https://www.toulouse.co.nz" target="_blank">
								<img src="assets/TOU footer logos 2x.png" alt="SignSync Logo and Toulouse Logo" className="float-left" id="footerLogo" style={{ "borderRadius": "0px" }} />
							</a>
							<div id="footerText">
								<p><span className="selectFont">PHONE</span> <a href="tel:04-977-1078">04 977 1078</a></p>
								<p><span className="selectFont">EMAIL</span> <a href="/#/contact">hello@signsync.co.nz</a></p>
								<p><span className="selectFont">WEB</span> <a href="http://www.toulouse.co.nz" target="_blank">www.toulouse.co.nz</a></p>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	};
}

export default Footer;