import React, { Fragment } from "react";
// import emailjs from "emailjs-com"
import Footer from "./Footer";
import $ from 'jquery';


class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
        };

        if (typeof props.location.state != 'undefined') {
            this.state.message = props.location.state.message;
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({ name: document.getElementById("name").value });
        this.setState({ email: document.getElementById("email").value });
        this.setState({ message: document.getElementById("message").value });
    }

    handleSubmit(event) {
        // alert(this.state.name + '\nYour message has been sent!' );

        console.log(this.state)
        // var URL = "https://xxnhhl47c9.execute-api.ap-southeast-2.amazonaws.com/prod/contact-us";

        var Namere = /[A-Za-z]{1}[A-Za-z]/;
        if (!Namere.test(this.state.name)) {
            alert("Name cannot have fewer than two characters.");
            return;
        }

        if (this.state.email === "") {
            alert("Please enter your email address.");
            return;
        }

        var reeamil = /^([\w-.]+@([\w-]+\.)+[\w-]{2,6})?$/;
        if (!reeamil.test(this.state.email)) {
            alert("Please enter a valid email address");
            return;
        }

        $.ajax({
            type: "POST",
            url: "https://xxnhhl47c9.execute-api.ap-southeast-2.amazonaws.com/prod/contact-us",
            dataType: "json",
            crossDomain: "true",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify({
                name: this.state.name,
                phone: "NA",
                email: this.state.email,
                desc: this.state.message
            }),
            success: function () {
                // clear form and show a success message
                alert("Thank you for getting in touch");
            },
            error: function () {
                // show an error message
                alert("Error Message has failed to send");
            }
        });

        this.resetForm()
    }

    resetForm() {
        document.getElementById("contactForm").children[0].reset();
    }

    render() {
        return (
            <Fragment>
                <h2 className="text-center" id="contact" >Contact</h2>
                <div class="maxWidth">
                    <div id="details">
                        <div id="questionsText">
                            <p>
                                Questions? Ideas? Get in touch with us and we’ll help you find out if SignSync is the solution for your project needs.
                            </p>
                        </div>
                        <div id="detailsText">
                            <p><span className="selectFont">PHONE</span> <a href="tel:04-977-1078">04 977 1078</a></p>
                            <p><span className="selectFont">EMAIL</span> hello@signsync.co.nz</p>
                            <p><span className="selectFont">WEB</span> <a href="http://www.toulouse.co.nz" target="_blank">www.toulouse.co.nz</a></p>
                            <p><span className="selectFont">ADDRESS</span> <a href="https://goo.gl/maps/eTtr1C8GLJrNUHsB6" target="_blank"> 85 Tasman St, Mount Cook, Wellington 6021</a></p>
                        </div>
                    </div>
                    <div id="contactForm">
                        <form onSubmit={this.handleSubmit} class="text-center">
                            <input type="text" id="name" className="smallInput" value={this.state.value} placeholder="Name" onChange={this.handleChange} />
                            <input type="text" id="email" className="smallInput" value={this.state.value} placeholder="Email" onChange={this.handleChange} />
                            <textarea id="message" value={this.state.message} placeholder="Message" onChange={this.handleChange} rows="10" cols="1"></textarea>
                            <button id="contactSubmit" className="standardButton" value="Submit" >Send Message</button>
                        </form>
                    </div>
                </div>
                <Footer />
            </Fragment>
        );
    };
}

export default Contact;