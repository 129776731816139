import React, { Fragment } from "react";
import Footer from "./Footer";
import ReactModal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

class Content extends React.Component {
    constructor() {
        super();

        this.state = {
        };

        this.handleOpenVideo = this.handleOpenVideo.bind(this);
        this.handleDemoForm = this.handleDemoForm.bind(this);
    }

    handleOpenVideo() {
        let link = document.createElement('a');
        link.href = "assets/Sign Sync Demo_720.mp4";
        link.target = "_blank";
        link.click();
    }

    handleCloseVideo() {
        this.setState({ showVideo: false });
    }

    handleDemoForm() {
        this.props.history.push({
            pathname: 'contact',
            state: {
                message: "Hi, your SignSync product looks amazing, can I please have a demo account to try all the features :)"
            }
        });
    }

    UNSAFE_componentWillMount() {
        ReactModal.setAppElement('body');
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <Fragment>
                {/* Container which you first see with big brand logo */}
                <div id="greetingBox">
                    <div className="maxWidth">
                        <div id="greetingBoxContent">
                            <div id="greetingBoxText" >
                                <h1>From your desk to display in just a few clicks with <span id="selectColorCNTSS">SignSync.</span></h1>
                            </div>
                            <div id="greetingImgBox">
                                <img src="assets/TOU-SignSync-brand-image-web-2x.png" id="SSBrand" alt="SignSync Brand logo" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="maxWidth">
                    <div id="middleTextCNT" className="text-center">
                        <p>
                            SignSync is the game-changing product that lets you manage digital signage remotely. Broadcast images, video and text in
                            minutes using a simple online interface that instantly syncs live screens. <span id="selectedColorCNTMid">No code or designer required.</span>
                        </p>
                    </div>

                    {/* Watch video see case studies section */}

                    <div id="previewContent" >
                        <div className="previewContentSide" onClick={this.handleOpenVideo}>
                            <img src="assets/intro_video.png" alt="Still from live preview video" />
                            <img id="playButton" src="assets/TOU video play arrow.png" alt="play button" />
                            <div className="previewText text-center">WATCH VIDEO</div>
                        </div>
                        <div className="previewContentSide">
                            <a href="#/cases">
                                <img onClick={this.handleCase} src="assets/intro_gallery.png" alt="screen display at PWC laundromat" />
                                <div id="previewTextGallery" className="previewText text-center"> GALLERY</div>
                            </a>
                        </div>

                        {/* <img onClick={this.handleOpenVideo} src="assets/TOU video play arrow.png" id="playButton" alt="play button" />
                        <div id="PreviewTextV" className="previewText text-center float-left" onClick={this.handleOpenVideo}> <a onClick={this.handleOpenVideo}>WATCH VIDEO</a> </div>
                        <img src="assets/intro_video.png" onClick={this.handleOpenVideo} id="stillPreview" alt="Still from live preview video" />
                        <a href="#/cases"><div id="pCaseStudies" className="previewText text-center"> GALLERY</div></a>
                        <a href="#/cases"><img onClick={this.handleCase} src="assets/intro_gallery.png" id="previewCaseImg" alt="screen display at PWC laundromat" /></a> */}
                    </div>

                    {/* Toulouse animations for editable and instant results*/}

                    <div id="illustrationContent">
                        <div id="editContent">
                            <img src="assets/TOU circle illustration edit 2x.png" id="editIllustration" className="illustrations float-left" alt="Edit content online illustration" />
                            <h3>Edit Content Online</h3>
                            <p>Make changes using our simple online interface – anywhere, anytime.</p>
                        </div>
                        <div id="SSImediately" className="float-left">
                            <img src="assets/TOU circle illustration sync 2x.png" id="SSIllustration" className="illustrations float-left" alt="Screens sync illustration" />
                            <h3>Screens sync immediately</h3>
                            <p>Whenever you save changes, they’ll appear immediately on your signage.</p>
                        </div>
                    </div>
                    {/* Set of 3 Images from previous jobs*/}
                    <div id="imagePreviewContent">
                        <img src="assets/intro1.png" id="pwcPreview" alt="Screen display at PWC laundromat" />
                        <img src="assets/intro2.png" id="goldenAgePreview" alt="Screen diplay at Lane gallery Puke Ariki 'Golden Age'" />
                        <img src="assets/intro3.png" id="cityGalleryPreview" alt="Screen display at City gallery" />
                    </div>
                    {/* Features area */}
                    <div id="features">
                        <h4>FEATURES</h4>
                        <ul >
                            <li>Simple easy-to-use content management interface</li>
                            <li>Reliable 24/7 non-Pc based playback system</li>
                            <li>Reliable 24/7 hardware</li>
                            <li>Cost effective hardware</li>
                            <li>Multiple screen configuration options to suit any budget</li>
                            <li>Single screens in landscape or portrait to multi- screen video walls</li>
                            <li>Video wall mode supports full resolution 1:1 graphics – no lost pixels</li>
                            <li>New Zealand based development and support team </li>
                            <li>Self or cloud hosted</li>
                            <li>Text based content can be edited within the management interface – no Photoshop or image editing applications needed</li>

                        </ul>
                    </div>
                </div>
                {/* Interested section */}
                <div id="interested" className="text-center">
                    <div className="maxWidth">
                        <h4>Interested? Try a demo account to see how easy it is to upload your content to digital signage.</h4>

                        <button onClick={this.handleDemoForm}>Request Demo Account</button>
                    </div>
                    {/* <button>USE PRICE CALCULATOR</button> */}
                </div>

                <Footer />
            </Fragment>
        );
    };
}

export default Content;